import { useState, useEffect } from "react";
import { Stack, Typography, Box, Container, Link, Skeleton, Button } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Image } from "mui-image";

import { Certificate, TraceV2Input } from "../types";
import CustomerEngagement from "./CustomerEngagement";
import { CreateConsumerScan } from "../graphql/mutation";
import { GetFreshnessMeter } from "../graphql/queries"
import DialogModal from "./Common/DialogModal";
import ImageCarousel from "./Carousels/ImageCarouel";
import ContentSlider from "./Carousels/ContentSlider";
import CertificatePreview from "./Common/CertificatePreview";
import { generateSessionId, storeSessionId, getSessionId } from "../utils";

dayjs.extend(localizedFormat);
dayjs.locale("en-in");

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffffff",
        },
    },
    typography: {
        h6: {
            fontWeight: "bold",
        },
    },
});

function TraceV2(props: TraceV2Input) {
    const { orgInfo, traces, traceItemID } = props;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [openCertificateModal, setOpenCertificateModal] = useState<boolean>(false);
    const [selectedCertificate, setSelectedCertificate] = useState<Certificate[]>([]);
    const [customerScanDetails, setcustomerScanDetails] = useState<any>({});
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [productName, setProductName] = useState<string>("");

    const handleReadMore = (content: string, productName: string) => {
        setModalContent(content);
        setProductName(productName);
        setOpen(true);
    };

    // Mutation to get consumer engegement details
    const [CreateConsumerEngagement] = useMutation(CreateConsumerScan);

    // Query to get freshness meter details
    const { data: FreshnessMeterDetails } = useQuery(GetFreshnessMeter, {
        variables: {
            filters: {
                "epicsEventId": traces[0].eventID
              }
        },
        skip: traces.length === 0
    });

    // Check Session ID and get the consumer engagement details
    useEffect(() => {
        let sessionId = getSessionId(traceItemID);
        if (!sessionId) {
            sessionId = generateSessionId();
            storeSessionId(traceItemID, sessionId);
        }
        setSessionId(sessionId);

        if (traceItemID) {
            CreateConsumerEngagement({
                variables: {
                    consumerScanInput: {
                        sessionId: sessionId,
                        timeZone: "Asia/Colkatta",
                        traceItemId: traceItemID
                    }
                }
            }).then(response => {
                const data = response.data.captureConsumerScan;
                setcustomerScanDetails(data);
                const now = new Date().getTime();
                const endDate = parseInt(data.endDate) * 1000;
                setIsExpired(!(data.isActive && !data.isDeprecated && now < endDate));
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setIsExpired(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const getFormattedUrl = (url: string) => {
        // If the URL doesn't already start with http:// or https://, prepend https://
        if (!/^https?:\/\//i.test(url)) {
            return `https://${url}`;
        }
        return url;
    };

    const handleOpenCertificate = (certificates: Certificate[]) => {
        setSelectedCertificate(certificates);
        setOpenCertificateModal(true);
    };

    // Function to filter certificates based on document level
    const filterCertificates = (certificates: Certificate[], level: string) => {
        return certificates.filter(cert => cert.documentLevel === level);
    };

    const getImageArray = (item) => {
        const images: { src: string, title: string }[] = [];
        if (item.thumbnail) images.push({ src: item.thumbnail, title: "Image from Event" });
        if (item.stockImage) images.push({ src: item.stockImage, title: "Stock image" });
        return images;
    };

    return (
        <ThemeProvider theme={theme}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{ width: "100%", maxWidth: "100%", padding: 0 }}>
                <Stack direction="column" justifyContent="center" alignItems="center"
                    sx={{ height: { sm: "150px", md: "175px", lg: "200px" }, width: "100%" }}>
                    {!imageLoaded && <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />}
                    <Image
                        src={orgInfo.companyBannerImagePath}
                        alt="banner"
                        style={{ objectFit: "fill", width: "100%", borderRadius: "5px", maxHeight: "100%", display: imageLoaded ? "block" : "none" }}
                        onLoad={handleImageLoad}
                    />
                </Stack>

                <Box
                    maxWidth={{ xs: "85%", md: "800px" }}
                    id="org-info"
                    sx={{ py: 1, px: 3, backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: 3, mt: 2, alignItems: "center", }}
                >
                    <ContentSlider
                        orgInfo={orgInfo}
                        customerScanDetails={customerScanDetails}
                        isExpired={isExpired}
                        loading={loading}
                    />
                </Box>

                {!isExpired && !loading &&
                    <Container maxWidth="md" id="consumer-info" sx={{ alignItems: "center", display: "flex", alignContent: "center", flexDirection: "column" }}>
                        <CustomerEngagement customerScanDetails={customerScanDetails} sessionId={sessionId} traceItemID={traceItemID} />
                    </Container>
                }
                <Typography variant="h5" sx={{ mt: 2 }}>Story of Your Product</Typography>
                <Timeline sx={{ px: { xs: 1, sm: 2 } }}>
                    {traces && traces.map((item, idx) => (
                        <TimelineItem key={idx} sx={{ pt: 0, mt: 0 }}>
                            <TimelineOppositeContent sx={{ width: { xs: 120, sm: 110 }, py: 1, px: 0 }}>
                                <Stack direction="column" justifyContent="right" alignItems="right">
                                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{item.eventDescription}</Typography>
                                    <Typography variant="caption">{item.eventTime}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "capitalize" }}>{item.location}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "capitalize", color: "gray", mt: 1 }}>
                                        {item.productDescription.slice(0, 80)}
                                        {item.productDescription.length > 80 && (
                                            <>
                                                ...
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => handleReadMore(item.productDescription, item.productDisplayName)}
                                                    sx={{ ml: 1, color: "#005bb5", fontSize: 10 }}
                                                >
                                                    Read more
                                                </Link>
                                            </>
                                        )}
                                    </Typography>
                                </Stack>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ p: 0, m: 1, border: "none", boxShadow: "none", backgroundColor: "white" }}>
                                    <Image src={idx === traces.length - 1 ? "/images/Tralexho-X.png" : "/images/Tralexho-O.png"} style={{ width: "25px" }} alt="tralexho" />
                                </TimelineDot>
                                {(idx < traces.length - 1) && <TimelineConnector sx={{ backgroundColor: "#000000" }} />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Stack direction="column" justifyContent="right" alignItems="right" sx={{ mb: 1 }}>
                                    <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>{item.productDisplayName}</Typography>
                                    {!imageLoaded && <Skeleton variant="rectangular" width={180} height={220} />}
                                    <Box sx={{ maxWidth: "180px", mb: 3 }}>
                                        <ImageCarousel 
                                            images={getImageArray(item)} 
                                            freshnessMeterDetails={idx === 0 ? FreshnessMeterDetails : undefined}
                                        />
                                    </Box>
                                    {filterCertificates(item.certificates, "location").length > 0 && (
                                        <Button
                                            onClick={() => handleOpenCertificate(filterCertificates(item.certificates, "location"))}
                                            sx={{
                                                color: "#0073e6",
                                                fontSize: 10,
                                                textDecoration: "none",
                                                '&:hover': {
                                                    textDecoration: "underline",
                                                    color: "#005bb5"
                                                },
                                            }}
                                        >
                                            Location certifications
                                        </Button>
                                    )}
                                    {
                                        item.certificates.length > 0 && (
                                            <>
                                                {filterCertificates(item.certificates, "product").length > 0 && (
                                                    <Button
                                                        onClick={() => handleOpenCertificate(filterCertificates(item.certificates, "product"))}
                                                        sx={{
                                                            color: "#0073e6",
                                                            textDecoration: "none",
                                                            fontSize: 10,
                                                            '&:hover': {
                                                                textDecoration: "underline",
                                                                color: "#005bb5"
                                                            },
                                                        }}
                                                    >
                                                        Product certifications
                                                    </Button>
                                                )}
                                            </>
                                        )
                                    }
                                </Stack>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
                <Link href={getFormattedUrl(orgInfo.companyWebsite)} target="_blank" rel="noopener noreferrer">
                    <Typography variant="caption" sx={{ mt: 1, color: "#005bb5" }}>
                        {orgInfo.companyWebsite}
                    </Typography>
                </Link>
                <Typography variant="caption" sx={{ mb: 2, mt: 1 }}>
                    Traceability powered by
                    <Link href="https://tralexho.com/" target="_blank" rel="noopener noreferrer" sx={{ color: "#005bb5", textDecoration: "none" }}>
                        &nbsp;TRALEXHO
                    </Link>
                </Typography>
                {openCertificateModal &&
                    <CertificatePreview
                        open={openCertificateModal}
                        onClose={() => setOpenCertificateModal(false)}
                        certificates={selectedCertificate}
                    />
                }
            </Stack>
            <DialogModal
                open={open}
                setOpen={setOpen}
                title={productName}
                message={modalContent}
                isIcon={false}
            />
        </ThemeProvider>
    );
}

export default TraceV2;