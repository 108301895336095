import { useLocation } from "react-router-dom";
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { getLocation, getProductClass } from "./events_master";
import { traceEvents } from "./api";
import TraceV2 from "./components/TraceV2";
import TraceV1 from "./components/TraceV1";
import { Box, Typography, Stack } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./loaderStyles.css";
import { OrgInfo, TraceEvent, TracesItem, TraceItemV2, TraceEventsResponse } from "./types";
import { getCertificatesForOrgInfo, getCertificatesForProductOrLocation } from "./utils";

dayjs.extend(localizedFormat);
dayjs.locale("en-in");

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

// The map of potential endpoints required to be invoked
// Key will be composed based on the env parameter in the URL
const endpointKey = {
    "ardra-0": "https://r1b1esqqa5.execute-api.ap-south-1.amazonaws.com",
    "ardra-1": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
    "taas-dev": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
    "taas-staging": "https://b57yoc3peh.execute-api.ap-south-1.amazonaws.com",
    "taas-prod": "https://vajo2y8mnk.execute-api.ap-south-1.amazonaws.com",
};

export default function Trace() {
    let query = useQuery();
    const trace_item = query.get("trace_item") || undefined;
    // const createdEpoch = query.get("eventEndEpoch") || undefined;
    const createdEpoch = undefined;
    const trace_item_id = query.get("trace_item_id") || undefined;
    let org_id = query.get("org_id") || undefined;
    if (org_id === undefined) {
        // trace_item_id = g-879f3f2052-Clove honey-20241211072318-01
        let item_parts = trace_item_id?.split("-") || [];
        if (item_parts.length > 2) {
            if (item_parts[0] === "g") {
                org_id = item_parts[1];
            } else {
                org_id = item_parts[0];
            }
        }
    }

    /*
      Env helps us determine which endpoint to invoke to fetch the trace
      For ardra organics, env is 0
      For seafood products, env is 1
      For Events V2 release, env may be:
        - dev for dev environment
        - stg for staging environment
        - will not exist 
    */
    const env = query.get("env");

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [traces, setTraces] = React.useState<TraceItemV2[]>([]);
    const [orgInfo, setOrgInfo] = React.useState<OrgInfo | null>(null);


    React.useEffect(() => {
        const getTraces = async () => {
            if ((!trace_item && !trace_item_id) || !org_id) {
                window.location.href = "https://tralexho.com/";
                return;
            }

            try {
                let base_url: string;
                let base_key: string;
                let results: TraceEventsResponse = [];
                let tmpResults: any[] = [];

                // ardra flow
                if (trace_item) {
                    base_key = "ardra";
                    base_url = endpointKey[`${base_key}-${env}`];
                    results = await traceEvents({ baseUrl: base_url, traceItem: trace_item, orgId: "adra-organics" });

                    results.forEach((res: any) => {
                        const productClass = getProductClass(res.output_product_instances.split("-")[0]);
                        const eventTime = dayjs(res.event_start_unix_epoch * 1000);
                        const item: TracesItem = {
                            userEvent: res.user_event,
                            eventDay: eventTime.format("L"),
                            eventTime: eventTime.format("h:mm A"),
                            location: getLocation(res.location).toLowerCase(),
                            productDescription: productClass.toLowerCase(),
                            thumbnail: res.image_thumbnail_presigned_url,
                        };
                        tmpResults.push(item);
                    });

                    setTraces(tmpResults);
                } else {
                    let base_url = process.env.REACT_APP_TRACE_URL || "";
                    if (window.location.hostname.indexOf("taas-trace.tralexho.com") > -1 && env === "staging") {
                        base_key = "taas";
                        base_url = endpointKey[`${base_key}-${env || "prod"}`];
                    }
                    results = await traceEvents({ baseUrl: base_url, traceItem: trace_item_id!, orgId: org_id, createdEpoch });

                    const { trace_events_list: traceEventsList } = results[1];
                    const { certificates } = results[2];
                    traceEventsList.forEach((traceEvent: TraceEvent) => {
                        const eventTime = traceEvent.event_start_unix_epoch ? dayjs(traceEvent.event_start_unix_epoch * 1000) : dayjs(null);
                        const products = traceEvent.output_products ? traceEvent.output_products : traceEvent.input_products ? traceEvent.input_products : [];
                        let productDescription = products.map(product => product.description).join(", ");
                        let productDisplayName = products.map(product => product.display_name).join(", ");
                        let stockImage = products.map(product => product.stock_image_presigned_url).filter(url => url).join(", ");
                        const item: TraceItemV2 = {
                            eventID: traceEvent.event_id,
                            userEvent: traceEvent.user_event,
                            eventDescription: traceEvent.event_description,
                            eventStartUnixEpoch: traceEvent.event_start_unix_epoch,
                            eventTime: eventTime.isValid() ? eventTime.format("ddd, DD MMM YYYY h:mm A") : "",
                            location: traceEvent.location,
                            productDescription: productDescription,
                            productDisplayName: productDisplayName,
                            stockImage: stockImage,
                            thumbnail: traceEvent.image_thumbnail_presigned_url,
                            certificates: getCertificatesForProductOrLocation(productDisplayName, traceEvent.location, certificates)
                        };
                        tmpResults.push(item);
                    });

                    tmpResults.sort((a, b) => a.eventStartUnixEpoch - b.eventStartUnixEpoch);
                    setTraces(tmpResults);

                    const organizationInfo = {
                        ...results[0].org_info,
                        certificates: getCertificatesForOrgInfo(certificates)
                    };
                    setOrgInfo(organizationInfo);
                }

                setLoading(false);
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        getTraces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderTrace = () => {
        if (traces && orgInfo) return <TraceV2 traces={traces} orgInfo={orgInfo} traceItemID={trace_item_id} orgId={org_id} />;
        return <TraceV1 traces={traces} />;
    };

    return (
        <Box sx={{ maxWidth: "100vw", minHeight: "100vh", display: "flex" }}>
            {loading ? (
                <Stack spacing={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
                    <Typography variant="h6" sx={{ color: '#000000' }}>Loading Your Product Journey...</Typography>
                    <div className="loader loader-3">
                        <div></div><div></div><div></div>
                    </div>
                </Stack>
            ) : error ? (
                <Stack sx={{ direction: "column", alignItems: "center", justifyContent: "center", spacing: 2, width: "100vw", height: "100vh" }}>
                    <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
                    <Typography variant="h6" sx={{ color: '#000000', textAlign: 'center' }}>
                        We couldn't find your trace. Please try again.
                    </Typography>
                </Stack>
            ) : (
                renderTrace()
            )}
        </Box>
    );
}
