import { Box, Typography } from "@mui/material";
import { FreshnessMeterProps } from "../../types";

export default function FreshnessMeter({
  position,
  isModal = false,
  onClick
}: FreshnessMeterProps) {
  return (
    <Box
      onClick={isModal ? undefined : onClick}
      sx={{
        position: "absolute",
        top: "15%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        padding: isModal ? "10px 10px 10px 10px" : "5px 5px 5px 5px",
        borderRadius: 1,
        boxShadow: 1,
        minHeight: "45px",
        cursor: isModal ? "default" : "pointer",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
        display: "flex",
        flexDirection: "column",
        gap: 0.7,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: isModal ? "0.9rem" : "0.6rem",
              fontWeight: "bold",
            }}
          >
            Fresh
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: isModal ? "0.9rem" : "0.6rem",
              fontWeight: "bold",
            }}
          >
            Not Fresh
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "16px",
            background: "linear-gradient(to right, #4CAF50, #F44336)",
            borderRadius: 0.5,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: `${position}%`,
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: "white",
              border: "2px solid #333",
              zIndex: 1,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 0.3,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: isModal ? "0.65rem" : "0.5rem",
            lineHeight: 1,
          }}
        >
          Powered by FREXHO Meter,
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontSize: isModal ? "0.6rem" : "0.45rem",
            color: "text.primary",
            lineHeight: 1,
          }}
        >
          (Patented AI Technology)
        </Typography>
      </Box>
    </Box>
  );
}
