import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { Image } from "mui-image";
import { ImageCarouselProps } from "../../types";
import { useState } from "react";
import FreshnessMeter from "./FreshnessMeter";
import FreshnessDetailModal from "./FreshnessDetailModal";

export default function ImageCarousel({ images, freshnessMeterDetails }: ImageCarouselProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const freshnessData = freshnessMeterDetails?.getFreshnessMeter[0];
  const positiononModal = freshnessData?.freshness === "Fresh" ? 4 : 95;
  const position = freshnessData?.freshness === "Fresh" ? 6 : 95;

  var settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };


  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{ height: "260px", textAlign: "center" }}
          >
            <Image
              src={image.src}
              style={{ objectFit: "contain", position: "relative" }}
              alt="trace"
            />
            {image.title === "Image from Event" && freshnessData && (
              <FreshnessMeter
                position={position}
                onClick={() => setIsModalOpen(true)}
              />
            )}
            <Typography variant="caption" color="gray" sx={{ mt: 1 }}>
              {image.title}
            </Typography>
          </Box>
        ))}
      </Slider>
      {freshnessData && (
        <FreshnessDetailModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          position={positiononModal}
          details={freshnessData}
        />
      )}
    </>
  );
}