import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FreshnessMeter from "./FreshnessMeter";
import { FreshnessDetailModalProps } from "../../types";

export default function FreshnessDetailModal({
  open,
  onClose,
  position,
  details
}: FreshnessDetailModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "300px",
          maxHeight: "80vh",
          width: "80%",
          m: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ p: 1.5, pb: 0 }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
            height: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 7,
          }}
        >
          <FreshnessMeter position={position} isModal={true} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              mb: 0.5,
              fontSize: "0.8rem",
              display: "block",
              textAlign: "left",
            }}
          >
            Product: {details?.product}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              mb: 0.5,
              fontSize: "0.8rem",
              display: "block",
              textAlign: "left",
            }}
          >
            FREXHO Meter rating: <span >{details?.freshness}</span>
          </Typography>

          <Typography
            variant="caption"
            sx={{
              mb: 1,
              fontSize: "0.8rem",
              display: "block",
              lineHeight: 1.4,
              textAlign: "left",
            }}
          >
            Explanation: {details?.explanation}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              display: "block",
              color: "text.secondary",
              fontSize: "0.7rem",
            }}
          >
            Our model focuses on several characteristics traditionally
            used by fishermen to assess fish freshness, including eyes, skin texture, and scales.
          </Typography>

          <Typography
            variant="caption"
            sx={{
              display: "block",
              color: "text.secondary",
              fontSize: "0.7rem",
            }}
          >

            Quality assessment by FREXHO Meter<sup>™</sup>—powered by patented AI technologies from TRALEXHO<sup>®</sup>.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
