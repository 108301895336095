export interface Certificate {
    certificateName: string;
    documentLevel: string;
    entities: string[];
    documentPaths: DocumentPath[];
    createdEpoch: number;
}

export interface OrgInfo {
    shortName: string;
    tagLine: string;
    storyLine: string;
    companyWebsite: string;
    companyBannerImagePath: string;
    logoPath: string;
    certificates: Certificate[];
}

export interface product {
    description: string;
    stock_image_presigned_url: string | null;
    display_name: string;
}

export interface TraceEvent {
    event_id: string;
    user_event: string;
    event_timezone_offset: string;
    event_start_unix_epoch: number;
    event_start_datetime: string;
    location: string;
    latitude: number;
    longitude: number;
    event_end_datetime: string;
    event_end_unix_epoch: number;
    input_products: product[] | null;
    output_products: product[];
    image_thumbnail_presigned_url: string;
    productDisplayName: string;
    event_description: string;
}

export interface TraceEventsListResponse {
    trace_events_list: TraceEvent[];
}

export interface OrgInfoResponse {
    org_info: OrgInfo;
}

export interface CertificatesResponse {
    certificates: Certificate[];
}

export type TraceEventsResponse = Array<OrgInfoResponse & TraceEventsListResponse & CertificatesResponse>;

export type FetchOptions = RequestInit;

export type TraceParams = {
    baseUrl: string,
    traceItem: string,
    orgId: string,
    createdEpoch?: string
}

export enum CertificateType {
    PDF = "pdf",
    IMAGE = "image",
}

export interface DocumentPath {
    id: string;
    url: string;
    fileType: CertificateType;
}

export type TracesItem = {
    userEvent: string,
    eventDay?: string,
    eventTime: string,
    location: string,
    productDescription: string,
    thumbnail: string,
    eventStartUnixEpoch?: number
}

export interface TraceItemV2 extends TracesItem {
    certificates: Certificate[];
    stockImage: string;
    productDisplayName: string;
    eventDescription: string;
    eventID: string;
}

export enum DocumentLevel {
    PRODUCT = "product",
    ORGANIZATION = "organization",
    LOCATION = "location"
}

export interface FlattenedCertificate {
    certificateName: string;
    documentLevel: string;
    entities: string[];
    createdEpoch: number;
    id: string;
    url: string;
    fileType: CertificateType;
}
export type TraceV2Input = {
    traces: TraceItemV2[],
    orgInfo: OrgInfo
    traceItemID: any
    orgId: any
}

export interface CustomerEngagementProps {
    customerScanDetails: {
        id: string;
        feedbackMethod: string;
        commentsFiled: string;
        submissionDisplayMessage: { key: string; value: string }[];
    };
    sessionId: string | null;
    traceItemID: string;
}

export interface ImageCarouselProps {
    images: { src: string, title: string }[];
    freshnessMeterDetails?: {
        getFreshnessMeter: {
            explanation: string;
            freshness: string;
            product: string;
        };
    };
}

export interface DialogModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    title: string;
    isIcon?: boolean;
}


export interface CustomerScanDetails {
    region: {
        engagementImage: string;
        engagementMessage: string;
    };
}

export interface ContentSliderProps {
    orgInfo: OrgInfo;
    customerScanDetails: CustomerScanDetails;
    isExpired: boolean;
    loading: boolean;
}

export interface FreshnessMeterProps {
  position: number;
  isModal?: boolean;
  onClick?: () => void;
}

export interface FreshnessDetailModalProps {
    open: boolean;
    onClose: () => void;
    position: number;
    details?: {
      freshness: string;
      product: string;
      explanation: string;
    };
  }